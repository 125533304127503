<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="state" class="form-label">* Departamento</label>
        <select
          class="form-select text-capitalize"
          name="state"
          @change="listCities($event.target.value)"
          v-model="satetSelected"
          required
        >
          <option
            v-for="state in stateList"
            v-bind:value="state._id"
            :key="state.name"
            >{{ state.name }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="city" class="form-label">* Municipio</label>
        <select
          class="form-select text-capitalize"
          name="city"
          v-model="citySelected"
          required
        >
          <option
            v-for="city in cityList"
            v-bind:value="city._id"
            :key="city.name"
            >{{ city.name }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="address" class="form-label">* Dirección</label>
        <input
          type="text"
          class="form-control"
          name="address"
          :minlength="addressMin"
          :maxlength="addressMax"
          v-model="address"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese una dirección para la sede
        </div>
      </div>
      <div class="mb-3">
        <label for="phone" class="form-label">Teléfono</label>
        <input
          type="number"
          class="form-control"
          name="phone"
          :min="phoneMin"
          :max="phoneMax"
          v-model="phone"
        />
        <div class="invalid-feedback">
          {{ errorMsg }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-primary shadow-primary"
          @click.prevent="submit()"
        >
          {{ submitName }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      stateList: [],
      cityList: [],
      satetSelected: "",
      citySelected: "",
      address: "",
      addressMin: 0,
      addressMax: 1,
      phone: "",
      phoneMin: 0,
      phoneMax: 1,
      errorMsg: ""
    };
  },
  mounted() {
    store.getData("state").then(stateData => {
      stateData.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      stateData.forEach((state, index) => {
        if (index === 0) {
          this.satetSelected = state._id;
        }
        this.stateList.push(state);
      });
      this.listCities(stateData[0]._id);
      this.mounted = true;
    });
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "branch") {
          this.addressMin = schema.data.address.min;
          this.addressMax = schema.data.address.max;
          this.phoneMin = schema.data.phone.min;
          this.phoneMax = schema.data.phone.max;
        }
      });
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    },
    listCities(stateID, defaultCity) {
      store.getData("city").then(cityData => {
        this.cityList = [];
        let indexCity;
        cityData.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });
        cityData.forEach(city => {
          if (city.state === stateID) {
            if (indexCity === undefined) {
              indexCity = city;
            }
            this.cityList.push(city);
          }
        });
        if (defaultCity !== undefined) {
          this.citySelected = defaultCity;
        } else {
          this.citySelected = indexCity._id;
        }
      });
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.state !== undefined && val.state !== null) {
              this.satetSelected = val.state;
              this.listCities(val.state, val.city);
            }
            if (val.address !== undefined && val.address !== null) {
              this.address = val.address;
            }
            if (val.phone !== undefined && val.phone !== null) {
              this.phone = val.phone;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    phone: function(val) {
      if (val < this.phoneMin) {
        this.errorMsg = `La número no debe ser inferior a ${this.phoneMin}`;
      } else if (val > this.phoneMax) {
        this.errorMsg = `La número no debe ser mayor a ${this.phoneMax}`;
      } else {
        this.errorMsg = "";
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Branch Form 3"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
