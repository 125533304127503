<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="ARL" class="form-label">* ARL</label>
        <select
          class="form-select text-capitalize"
          name="ARL"
          v-model="ARL"
          required
        >
          <option
            v-for="ARL in ARLList"
            v-bind:value="ARL._id"
            :key="ARL.name"
            >{{ ARL.name }}</option
          >
        </select>
      </div>
      <div class="mb-3">
        <label for="description" class="form-label">* Descripción</label>
        <textarea
          class="form-control"
          name="description"
          rows="3"
          :minlength="descriptionMin"
          :maxlength="descriptionMax"
          required
          v-model="description"
        ></textarea>
        <div class="invalid-feedback">
          {{ errorMsg }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('back')"
        >
          Anterior
        </button>
        <button
          class="btn btn-secondary shadow-secondary"
          @click.prevent="next('forward')"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "sectionPosition", "submitValidate", "defaultData"],
  methods: {
    next(event) {
      this.$emit("moveForm", event);
    }
  },
  data() {
    return {
      initDefault: true,
      description: "",
      errorMsg: "",
      descriptionMin: 0,
      descriptionMax: 1,
      ARL: "",
      ARLList: []
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "branch") {
          this.descriptionMin = schema.data.description.min;
          this.descriptionMax = schema.data.description.max;
        }
      });
    });
    store.getData("ARL").then(ARLData => {
      ARLData.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      ARLData.forEach(ARL => {
        this.ARLList.push(ARL);
      });
    });
  },
  watch: {
    defaultData: function(val) {
      if (
        val.description !== undefined &&
        val.description !== null &&
        this.initDefault
      ) {
        this.description = val.description;
        this.initDefault = false;
      }
      if (val.ARL) {
        this.ARL = val.ARL;
      }
    },
    description: function(val) {
      if (val.length < this.descriptionMin) {
        this.errorMsg = `La descripción debe tener al menos ${this.descriptionMin} caracteres`;
      } else if (val.length > this.descriptionMax) {
        this.errorMsg = `La descripción debe tener menos de ${this.descriptionMax} caracteres`;
      } else {
        this.errorMsg = "";
      }
    },
    submitValidate: function(val) {
      if (this.description === "") {
        this.errorMsg = "Por favor ingrese una descripción para la sede";
      }

      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Branch Form 2"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
