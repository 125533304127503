<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <input
          @change="getFileClick"
          type="file"
          name="banner"
          accept=".png,.jpg,.jpeg,.gif,.webp"
          class="fileArea__input"
          hidden
        />
      </div>
      <div
        @click="clickFileInput"
        @dragover.prevent="overFile"
        @dragleave="leaveFile"
        @dragend="leaveFile"
        @drop.prevent="getFile($event)"
        class="fileArea rounded d-flex align-items-center justify-content-center"
      >
        <p class="fileArea__prompt">De click aquí para subir una imagen</p>
      </div>
      <p class="text-center mb-3 mt-1">
        Formatos Soportados: PNG, JPG, GIF, WEBP
      </p>
      <div v-if="deleteActivate" class="d-flex justify-content-between">
        <button
          @click.prevent="deleteBranch"
          class="btn btn-danger shadow-danger"
        >
          Eliminar Sede
        </button>
        <button
          @click.prevent="next('forward')"
          class="btn btn-secondary shadow-secondary"
        >
          Siguiente
        </button>
      </div>
      <div v-else class="d-flex justify-content-end">
        <button
          @click.prevent="next('forward')"
          class="btn btn-secondary shadow-secondary"
        >
          Siguiente
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: ["sectionName", "defaultData", "deleteActivate"],
  data() {
    return {
      initDefault: true
    };
  },
  watch: {
    defaultData: function(val) {
      if (val.banner !== undefined && val.banner !== null && this.initDefault) {
        const fileArea = document.getElementsByClassName("fileArea")[0];
        const fileAreaThumb = document.createElement("div");
        const fileAreaPrompt = document.getElementsByClassName(
          "fileArea__prompt"
        )[0];

        if (fileAreaPrompt) {
          fileAreaPrompt.remove();
        }

        fileAreaThumb.classList.add("fileArea__thumb");
        fileAreaThumb.classList.add("rounded");
        fileArea.appendChild(fileAreaThumb);

        fileAreaThumb.style.backgroundImage = `url('${val.banner}')`;
        fileAreaThumb.style.backgroundColor = "var(--main-bg)";
        fileAreaThumb.style.backgroundSize = "cover";
        fileAreaThumb.style.backgroundPosition = "center";
        fileAreaThumb.style.height = "100%";
        fileAreaThumb.style.width = "100%";
        this.initDefault = false;
      }
    }
  },
  methods: {
    deleteBranch() {
      Swal.fire({
        title: "¿Eliminar Sede?",
        showCancelButton: true,
        confirmButtonText: `Eliminar`,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#dc3545",
        cancelButtonColor: "#6c757d"
      }).then(result => {
        if (result.isConfirmed) {
          this.$emit("deleteBranch", true);
        }
      });
    },
    next(event) {
      this.$emit("moveForm", event);
    },
    overFile() {
      const fileArea = document.getElementsByClassName("fileArea")[0];
      fileArea.classList.add("fileArea--over");
    },
    leaveFile() {
      const fileArea = document.getElementsByClassName("fileArea")[0];
      fileArea.classList.remove("fileArea--over");
    },
    updateThumbnail(fileArea, fileAreaThumb, fileAreaPrompt, files) {
      if (fileAreaPrompt) {
        fileAreaPrompt.remove();
      }
      if (!fileAreaThumb) {
        fileAreaThumb = document.createElement("div");
        fileAreaThumb.classList.add("fileArea__thumb");
        fileAreaThumb.classList.add("rounded");
        fileArea.appendChild(fileAreaThumb);
      }
      if (files[0].type.startsWith("image/")) {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
          fileAreaThumb.style.backgroundImage = `url('${reader.result}')`;
          fileAreaThumb.style.backgroundColor = "var(--main-bg)";
          fileAreaThumb.style.backgroundSize = "cover";
          fileAreaThumb.style.backgroundPosition = "center";
          fileAreaThumb.style.height = "100%";
          fileAreaThumb.style.width = "100%";
        };
      } else {
        fileAreaThumb.style.backgroundImage = null;
      }
    },
    clickFileInput() {
      const fileAreaInput = document.getElementsByClassName(
        "fileArea__input"
      )[0];
      fileAreaInput.click();
    },
    getFileClick() {
      const fileArea = document.getElementsByClassName("fileArea")[0];
      const fileAreaPrompt = document.getElementsByClassName(
        "fileArea__prompt"
      )[0];
      const fileAreaThumb = document.getElementsByClassName(
        "fileArea__thumb"
      )[0];
      const fileAreaInput = document.getElementsByClassName(
        "fileArea__input"
      )[0];
      if (fileAreaInput.files.length) {
        const files = fileAreaInput.files;
        this.updateThumbnail(fileArea, fileAreaThumb, fileAreaPrompt, files);
      }
    },
    getFile(event) {
      const fileArea = document.getElementsByClassName("fileArea")[0];
      const fileAreaInput = document.getElementsByClassName(
        "fileArea__input"
      )[0];
      const fileAreaPrompt = document.getElementsByClassName(
        "fileArea__prompt"
      )[0];
      const files = event.dataTransfer.files;
      const fileAreaThumb = document.getElementsByClassName(
        "fileArea__thumb"
      )[0];
      if (files.length) {
        fileAreaInput.files = files;
        this.updateThumbnail(fileArea, fileAreaThumb, fileAreaPrompt, files);
      }
      fileArea.classList.remove("fileArea--over");
    }
  },
  name: "Branch Form 1"
};
</script>

<style lang="css" scoped>
.fileArea {
  cursor: pointer;
  width: 100%;
  height: 160px;
  border: 1px black dashed;
}
.fileArea--over {
  border: 2px black solid;
  transform: rotate(-2deg);
}
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
